
import { defineComponent, inject, onBeforeMount, ref } from "vue";
// @ts-expect-error
import { loadingSpinner } from "aml";
import ApiClient from "@/apiClient";
import DistributorSettingsService from "@/services/distributor-settings-service";
import { DistributorSettingPM, DistributorSettingVM } from "@/models";
import { EditableGrid } from "@/components";
import { GridColumnProps } from "@progress/kendo-vue-grid";
import AddDistributorSettingDialog from "./add-distributor-setting-dialog.vue";
import { UserAuthHelper } from "@/helpers";

export default defineComponent({
  name: "DistributorSettings",
  components: {
    EditableGrid,
    loadingSpinner,
    AddDistributorSettingDialog,
  },
  setup() {
    const settings = ref<DistributorSettingVM[]>([]);
    const apiclient = inject("api") as ApiClient;
    const userAuth = inject("userAuth") as UserAuthHelper;
    const $errorModal = inject("$errorModal") as (error: any) => void;
    const distributorSettingService = new DistributorSettingsService(apiclient);
    const isAddDialogVisible = ref(false);

    const columns: GridColumnProps[] = [
      {
        field: "distributorName",
        title: "Distributor",
        editable: false,
      },
      {
        field: "registrationRevenueMinimum",
        title: "Design Registration Minimum Revenue",
        editable: true,
        editor: "numeric",
        format: "{0:c0}",
      },
      { 
        cell: "editItemTemplate", 
        width: "200px", 
        title: "Actions", 
        filterable: false,
        hidden: !userAuth.canManageDistributorSettings
      },
    ];

    const isLoading = ref(true);

    const loadDistributorSettings = async () => {
      isLoading.value = true;
      try {
        settings.value = await distributorSettingService.getDistributorSettings();
      } catch (error) {
        $errorModal(error);
      } finally {
        isLoading.value = false;
      }
    };

    onBeforeMount(async () => {
      await loadDistributorSettings();
    });

    const validator = (item: DistributorSettingVM): string | boolean => {
      const isNegative = item.registrationRevenueMinimum < 0 && "Amount must be positive number.";
      const isNull = item.registrationRevenueMinimum === null && "Minimum revenue amount is required.";

      return isNegative || isNull;
    };

    const onAddNew = () => {
      isAddDialogVisible.value = true;
    };

    const addNewSetting = async (item: DistributorSettingPM) => {
      isLoading.value = true;
      try {
        const newSetting = await distributorSettingService.createDistributorSetting(item);
        const newItem = new DistributorSettingVM();
        Object.assign(newItem, newSetting);
        newItem.distributorName = item.name;
        settings.value.unshift(newItem);
      } catch (error) {
        $errorModal(error);
      } finally {
        isLoading.value = false;
      }
    };
    const updateSetting = async (item: DistributorSettingVM) => {
      isLoading.value = true;
      try {
        const postModel = new DistributorSettingPM();
        Object.assign(postModel, item);
        const savedItem = await distributorSettingService.updateDistributorSetting(postModel);
        Object.assign(item, savedItem);
      } catch (error) {
        $errorModal(error);
      } finally {
        isLoading.value = false;
      }
    };

    const onAddDistributorModalClose = () => {
      isAddDialogVisible.value = false;
    };

    const onNewDistributorAdded = async (setting: DistributorSettingPM) => {
      await addNewSetting(setting);
      isAddDialogVisible.value = false;      
    };

    return {
      columns,
      isLoading,
      settings,
      validator,
      updateSetting,
      onAddNew,
      isAddDialogVisible,
      onAddDistributorModalClose,
      onNewDistributorAdded,
      userAuth
    };
  },
});
