
//@ts-expect-error
import { modalCard } from "aml";
import { DistributorService, DistributorSettingsService } from "@/services";
import { defineComponent } from "vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { DistributorSettingPM } from "@/models";
import { LoadingWrapper } from "@/components";
import { OptionVM } from "@/interfaces";

export default defineComponent({
  components: {
    modalCard,
    DropDownList,
    LoadingWrapper,
    NumericTextBox,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["modalClosed", "newDistributorAdded"],
  inject: ["api"],
  data() {
    return {
      distributorService: new DistributorService(this.api),
      distributorSettingService: new DistributorSettingsService(this.api),
      registrationRevenueMinimum: 0,
      distributors: [] as OptionVM[],
      filteredDistributors: [] as OptionVM[],
      isLoading: false,
      newDistributor: {} as OptionVM,
      defaultItem: { distributorName: "Select distributor..."},
      defaultMinimum: 0,
      revenueFormat: {
        style: 'currency', 
        minimumFractionDigits: 0, 
        maximumFractionDigits: 0
      },
      formId: "distributorSettingForm"
    };
  },
  methods: {
    onCloseClick() {
      this.registrationRevenueMinimum = this.defaultMinimum;
      this.$emit("modalClosed");
    },
    async loadDistributors() {
      this.isLoading = true;
      try {
        const result = await this.distributorService.getDistributorsWithoutSettings();
        this.registrationRevenueMinimum = this.defaultMinimum = result.registrationRevenueMinimum;
        this.distributors = result.distributors;
        this.filteredDistributors = [...this.distributors];
      } catch (error) {
        this.$errorModal(error);
      } finally {
        this.isLoading = false;
      }
    },
    onFilterChange(event: any) {
      this.filteredDistributors = [
        ...this.distributors.filter(c => c.name?.toLowerCase().includes(event.filter?.value)),
      ];
    },
    onDropdownChange(event: any) {
      this.newDistributor = event.value;
      // ts error should be solved when this PR is merged: https://github.com/vuejs/vue-next/pull/3608
      // @ts-expect-error
      this.$nextTick(() => this.$validate(this.formId));
    },
    async onSaveClick() {
      if(this.$validate(this.formId)?.length) return;
      const setting = new DistributorSettingPM();
      setting.code = this.newDistributor.code;
      setting.name = this.newDistributor.name || "";
      setting.registrationRevenueMinimum = this.registrationRevenueMinimum;
      this.$emit("newDistributorAdded", setting);
    },
  },
  watch: {
    isVisible(newVal: boolean) {
      if(newVal) this.loadDistributors();
    }
  }
});
